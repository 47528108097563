@tailwind base;
@tailwind components;
@tailwind utilities;

.animate__animated.animate__fadeIn {
  --animate-duration: 500ms;
}

.animate__animated.animate__fadeOut {
  --animate-duration: 250ms;
}

.swal2-html-container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.header {
  font: 600 18px 'Segoe UI', Ubuntu, Sans-Serif;
  fill: #d500f9;
  animation: fadeInAnimation 0.8s ease-in-out forwards;
}

.stat {
  font: 600 14px 'Segoe UI', Ubuntu, "Helvetica Neue", Sans-Serif;
  fill: #333;
}

.easy {
  fill: #00a152;
}

.medium {
  fill: #ff9100
}

.hard {
  fill: #f50057
}

.stagger {
  opacity: 0;
  animation: fadeInAnimation 0.3s ease-in-out forwards;
}

.acceptance-text {
  font: 800 24px 'Segoe UI', Ubuntu, Sans-Serif;
  fill: #333;
}

.acceptance-title {
  font: 600 14px 'Segoe UI', Ubuntu, "Helvetica Neue", Sans-Serif;
  fill: #333;
}

.bold {
  font-weight: 700
}

.acceptance-circle-rim {
  stroke: #d500f9;
  fill: none;
  stroke-width: 6;
  opacity: 0.2;
}

.acceptance-circle {
  stroke: #d500f9;
  stroke-dasharray: 377;
  fill: none;
  stroke-width: 6;
  stroke-linecap: round;
  opacity: 0.8;
  transform-origin: -10px 8px;
  transform: rotate(-90deg);
  animation: acceptanceAnimation 1s forwards ease-in-out;
}
:root {
  --stroke-end-width: 30;
}
@keyframes acceptanceAnimation {
  from {
    stroke-dashoffset: 377;
  }
  to {
    stroke-dashoffset: var(--stroke-end-width);
  }
}
/* Animations */

@keyframes scaleInAnimation {
  from {
    transform: translate(-5px, 5px) scale(0);
  }
  to {
    transform: translate(-5px, 5px) scale(1);
  }
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:root {
  --indigo-600: #7c3aed;
  --indigo-700: #4338ca;
  --indigo-unselected: #645cc5;
}

.mobileProfHeight {
  height: calc(100vh - 63px);
}

.currProfile {
  scroll-margin-top: 30px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide the scrollbar */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Scroll to anchor links smoothly */
html {
  scroll-behavior: smooth;
}

/* Amimate hero text color */
.hero-text {
  text-align: center;
  background-image: linear-gradient(
    -90deg,
    rgb(239 68 68) 00%,
    #6366f1 20%,
    rgb(14 165 233) 40%,
    rgb(59 130 246) 60%,
    rgb(236 72 153) 80%,
    rgb(239 68 68) 100%

  );
  background-size: 400% auto;
  animation: shine 5s linear infinite;
}

@keyframes shine {
  from {
    background-position: 400% center;
  }
  to {
    background-position: 267% center;
  }
}

.burger-menu .bar {
  display: block;
  width: 100%;
  height: 5px;
  background-color: #6366f1;
  margin: 3px 0;
  transition: 0.3s;
}

.burger-menu.is-active div:nth-child(1) {
  transform: rotate(-45deg) translate(-8px, 6px);
}

.burger-menu.is-active div:nth-child(2) {
  opacity: 0;
}

.burger-menu.is-active div:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -3px);
}

.mobile-nav {
  position: fixed;
  top: 0;
  right: 100%;
  width: 100%;
  min-height: 100vh;
  display: block;
  z-index: 40;
  background-color: white;
  padding-top: 58px;
  transition: ease-in-out 0.4s;
}

.mobile-nav.is-active {
  right: 0;
}

.mobile-nav a {
  display: block;
  width: 100%;
  max-width: 200px;
  margin-top: 10px;
  padding: 12px 16px;
  color: #6366f1;
  text-decoration: none;
}

.notion-embedded {
  width: 100%;
  height: 500px;
  padding: none;
}

/* Pledge Calendar Table Styles */

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 8px;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.ktp-color {
  color: #1e3a8a;
}

th {
  background-color: var(--indigo-700);
  color: white;
}

.th-0 {
  border-radius: 10px 0 0 0;
}

.th-4 {
  border-radius: 0 10px 0 0;
}

td {
  background-color: rgb(238, 238, 238);
}

form {
  display: flex;
  gap: 5px;
}

form td:last-child {
  display: flex;
  justify-content: space-evenly;
}

.rbc-event {
  background-color: var(--indigo-600);
}

.rbc-event.rbv-selected {
  background-color: var(--indigo-700) !important;
}

.rbc-event:focus {
  outline: 5px auto var(--indigo-700) !important;
}

.rbc-today {
  background-color: #e3e3ff !important;
}

.modal-portal {
  z-index: 10;
  position: absolute;
}
